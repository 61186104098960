const SchoolIndex = () => import("../views/school/Index");
const SchoolForm = () => import("../views/school/Form");

export default [
  {
    path: "/school",
    name: "School.Index",
    component: SchoolIndex,
    meta: {
      requiresAuth: true,
      roles: ["admin"]
    }
  },
  {
    path: "/school/create",
    name: "School.Create",
    component: SchoolForm,
    meta: {
      requiresAuth: true,
      roles: ["admin"]
    }
  },
  {
    path: "/school/:id/edit",
    name: "School.Edit",
    component: SchoolForm,
    meta: {
      requiresAuth: true,
      roles: ["admin"]
    }
  }
];
