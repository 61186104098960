import querystring from "querystring";
import api from "./api";
import authHeader from "@/helpers/auth-header";

const moduleName = "school";

export default {
  index(params) {
    return api().get(`${moduleName}?${querystring.stringify(params)}`, {
      headers: authHeader()
    });
  },
  create(data) {
    return api().post(`${moduleName}/create`, data, {
      headers: authHeader()
    });
  },
  find(id) {
    return api().get(`${moduleName}/${id}/find`, {
      headers: authHeader()
    });
  },
  update(id, data) {
    return api().put(`${moduleName}/${id}/update`, data, {
      headers: authHeader()
    });
  },
  delete(id) {
    return api().delete(`${moduleName}/${id}/delete`, {
      headers: authHeader()
    });
  },
  search(query) {
    return api().get(`${moduleName}/search?query=${query}`, {
      headers: authHeader()
    });
  }
};
