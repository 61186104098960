import axios from "axios";
import { auth } from "@/firebase";
import { EventBus } from "@/eventBus";
import router from "@/router";

const { NODE_ENV, VUE_APP_API, VUE_APP_API_DEV } = process.env;
const UNAUTHORIZED = 401;
const UNAUTHORIZED_MESSAGE = "You are not authorized to accessing this page";
const INVALID_TOKEN_MESSAGE = "Invalid token";

const instance = axios.create({
  baseURL: NODE_ENV === "production" ? VUE_APP_API : VUE_APP_API_DEV
});
instance.interceptors.response.use(
  function(response) {
    return response;
  },
  async function(error) {
    const {
      status,
      data: { message },
      config: { headers, method, url, data }
    } = error.response;
    let showSnackBar = true;
    if (status === UNAUTHORIZED) {
      if (message === UNAUTHORIZED_MESSAGE) {
        router.push({ path: "/" });
      }
      if (message === INVALID_TOKEN_MESSAGE) {
        const refreshedToken = await auth.currentUser.getIdToken(true);
        localStorage.setItem("token", refreshedToken);
        const modifiedHeaders = {
          ...headers,
          Authorization: `Bearer ${refreshedToken}`
        };
        return await instance({ method, url, headers: modifiedHeaders, data });
      }
    }

    if (showSnackBar) {
      EventBus.$emit("createSnackbar", {
        snackbar_message: error.response.data.message,
        variant: "error"
      });
    }
    return Promise.reject(error);
  }
);

export default () => {
  return instance;
};
