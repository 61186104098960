import addSeconds from "date-fns/addSeconds";
import Vuetify from "@/plugins/vuetify";
import SubtestService from "@/services/subtest";
import TimeService from "@/services/time";

const initialState = {
  id: null,
  questions: [
    {
      position: 0,
      isAnswered: false,
      questionId: 0
    }
  ],
  question: {
    id: "",
    question: "",
    answers: []
  },
  subtest: {
    name: "",
    subjectName: ""
  },
  questionNumber: 1,
  selectedAnswer: undefined,
  originalSelectedAnswer: undefined,
  countdown: "",
  disabledRadioAnswer: false,
  time: new Date(),
  timer: null
};

export default {
  namespaced: true,
  state: {
    ...initialState
  },
  actions: {
    setId({ commit }, id) {
      commit("setId", id);
    },
    async findRunningSubtestQuestions({ commit, state }) {
      const questions = await SubtestService.findRunningSubtestQuestions(
        state.id
      );

      commit("setQuestions", questions);
    },
    setQuestionNumber({ commit }, questionNumber) {
      commit("setQuestionNumber", questionNumber);
    },
    setQuestion({ commit }, question) {
      commit("setQuestion", question);
    },
    setSelectedAnswer({ commit }, selectedAnswer) {
      commit("setSelectedAnswer", selectedAnswer);
    },
    setOriginalSelectedAnswer({ commit }, originalSelectedAnswer) {
      commit("setOriginalSelectedAnswer", originalSelectedAnswer);
    },
    setSubtest({ commit }, subtest) {
      commit("setSubtest", subtest);
    },
    setCountdown({ commit }, countdown) {
      commit("setCountdown", countdown);
    },
    setTime({ commit }, time) {
      commit("setTime", time);
    },
    setTimer({ commit }, timer) {
      commit("setTimer", timer);
    },
    setDisabledRadioAnswer({ commit }, disabled) {
      commit("setDisabledRadioAnswer", disabled);
    },
    resetState({ commit }) {
      commit("resetState");
    },
    async loadQuestion({ commit, state, dispatch }, { questionId, position }) {
      if (
        state.selectedAnswer !== undefined &&
        state.selectedAnswer !== state.originalSelectedAnswer &&
        state.countdown !== Vuetify.framework.lang.t("$vuetify.subtestEnded")
      ) {
        dispatch("answerQuestion");
      }

      commit("setQuestionNumber", position);
      commit("setQuestion", { question: "" });
      commit("setSelectedAnswer", undefined);

      const question = await SubtestService.findQuestion(questionId, {
        running_subtest_id: state.id
      });
      commit("setQuestion", question.question);
      const selectedAnswer = question.testAnswer?.answerId || undefined;
      commit("setSelectedAnswer", selectedAnswer);
      commit("setOriginalSelectedAnswer", selectedAnswer);
    },
    async answerQuestion({ state }) {
      state.questions.find(
        question => question.position === state.questionNumber
      ).isAnswered = true;

      await SubtestService.answerQuestion(state.id, {
        question_id: state.question.id,
        answer_id: state.selectedAnswer
      });
    },
    async findRunningSubtest({ commit, state, dispatch }) {
      const fetchRunningSubest = await SubtestService.findRunningSubtest(
        state.id
      );
      commit("setSubtest", fetchRunningSubest);
      dispatch("countdownTestEnd", fetchRunningSubest.testEnd);
    },
    countdownTestEnd({ commit, state, dispatch }, dateTime) {
      const end = new Date(dateTime);
      let secondsPassed = 1;

      const SECOND = 1000;
      const MINUTE = SECOND * 60;
      const HOUR = MINUTE * 60;
      const DAY = HOUR * 24;

      async function showRemaining() {
        if (
          state.countdown === Vuetify.framework.lang.t("$vuetify.subtestEnded")
        ) {
          commit(
            "setCountdown",
            Vuetify.framework.lang.t("$vuetify.subtestEnded")
          );
          clearInterval(state.timer);
          return;
        }

        const now = state.time;
        const distance = end - now;
        if (distance < 0) {
          commit(
            "setCountdown",
            Vuetify.framework.lang.t("$vuetify.subtestEnded")
          );
          commit("setDisabledRadioAnswer", true);
          clearInterval(state.timer);
          return;
        }

        if (secondsPassed === 100) {
          secondsPassed = 0;
          await dispatch("getTime");
        }

        const hours = Math.floor((distance % DAY) / HOUR);
        const minutes = Math.floor((distance % HOUR) / MINUTE);
        const seconds = Math.floor((distance % MINUTE) / SECOND);

        commit(
          "setCountdown",
          `${("00" + hours).slice(-2)}:${("00" + minutes).slice(-2)}:${(
            "00" + seconds
          ).slice(-2)}`
        );
        commit("setTime", addSeconds(state.time, 1));
        secondsPassed++;
      }

      commit("setTimer", setInterval(showRemaining, 1000));
    },
    async getTime({ commit }) {
      const date = await TimeService.time();
      commit("setTime", new Date(date));
    }
  },
  mutations: {
    setId(state, id) {
      state.id = id;
    },
    setQuestions(state, questions) {
      state.questions = questions;
    },
    setQuestionNumber(state, questionNumber) {
      state.questionNumber = questionNumber;
    },
    setQuestion(state, question) {
      state.question = question;
    },
    setSelectedAnswer(state, selectedAnswer) {
      state.selectedAnswer = selectedAnswer;
    },
    setOriginalSelectedAnswer(state, originalSelectedAnswer) {
      state.originalSelectedAnswer = originalSelectedAnswer;
    },
    setSubtest(state, subtest) {
      state.subtest = subtest;
    },
    setCountdown(state, countdown) {
      state.countdown = countdown;
    },
    setTime(state, time) {
      state.time = time;
    },
    setTimer(state, timer) {
      state.timer = timer;
    },
    setDisabledRadioAnswer(state, disabled) {
      state.disabledRadioAnswer = disabled;
    },
    resetState(state) {
      Object.assign(state, initialState);
    }
  },
  getters: {
    countdown: ({ countdown }) => countdown,
    disabledRadioAnswer: ({ disabledRadioAnswer }) => disabledRadioAnswer,
    findQuestionIdByPosition: ({ questions }) => position =>
      questions.find(question => question.position === position).questionId,
    id: ({ id }) => id,
    originalSelectedAnswer: ({ originalSelectedAnswer }) =>
      originalSelectedAnswer,
    question: ({ question }) => question,
    questionNumber: ({ questionNumber }) => questionNumber,
    questions: ({ questions }) => questions,
    selectedAnswer: ({ selectedAnswer }) => selectedAnswer,
    subtest: ({ subtest }) => subtest,
    timer: ({ timer }) => timer,
    unansweredQuestionCount: ({ questions }) =>
      questions.filter(({ isAnswered }) => !isAnswered).length
  }
};
