<template>
  <v-window-item :value="windowName">
    <v-toolbar flat>
      <v-btn @click="step = 'menu'" icon>
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <v-toolbar-title>
        {{ currentTitle }}
      </v-toolbar-title>
    </v-toolbar>
    <div class="pa-4">
      <v-form
        v-model="valid"
        ref="form"
        @submit.prevent="submit"
        lazy-validation
      >
        <v-text-field
          v-model="form.current_password"
          @click:append="showCurrentPassword = !showCurrentPassword"
          :append-icon="showCurrentPassword ? 'mdi-eye' : 'mdi-eye-off'"
          :type="showCurrentPassword ? 'text' : 'password'"
          :disabled="loading"
          :rules="rules.password"
          label="Current Password"
          required
        />
        <v-text-field
          v-model="form.new_password"
          @click:append="showNewPassword = !showNewPassword"
          :append-icon="showNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
          :type="showNewPassword ? 'text' : 'password'"
          :disabled="loading"
          :rules="rules.password"
          label="New Password"
          required
        />
        <v-text-field
          v-model="form.password_confirmation"
          @click:append="showPasswordConfirmation = !showPasswordConfirmation"
          :append-icon="showPasswordConfirmation ? 'mdi-eye' : 'mdi-eye-off'"
          :type="showPasswordConfirmation ? 'text' : 'password'"
          :disabled="loading"
          :rules="passwordConfirmationRule"
          label="Password Confirmation"
          required
        />
        <v-btn
          :disabled="!valid || loading"
          :loading="loading"
          color="success"
          class="mt-2"
          type="submit"
        >
          Submit
        </v-btn>
      </v-form>
    </div>
  </v-window-item>
</template>

<script>
import { mapGetters } from "vuex";
import {
  auth,
  credential,
  reauthenticateWithCredential,
  updatePassword
} from "@/firebase";

export default {
  name: "Settings.ResetPasswordWindow",
  data: () => ({
    windowName: "reset-password",
    valid: false,
    loading: false,
    showCurrentPassword: false,
    showNewPassword: false,
    showPasswordConfirmation: false,
    form: {
      current_password: "",
      new_password: "",
      password_confirmation: ""
    },
    rules: {
      password: [
        v => !!v || "Password is required",
        v =>
          (v.length >= 6 && v.length <= 50) ||
          "Password must be greater than 6 and less then 30"
      ],
      password_confirmation: [v => !!v || "Password confirmation is required"]
    }
  }),
  computed: {
    ...mapGetters("user", ["email"]),
    ...mapGetters("accountSettings", ["menus"]),
    step: {
      get() {
        return this.$store.getters["accountSettings/step"];
      },
      set(step) {
        this.$store.commit("accountSettings/setStep", step);
      }
    },
    currentTitle() {
      return this.step !== "menu"
        ? this.menus.find(menu => menu.id === this.step).title
        : "Menu";
    },
    passwordConfirmationRule() {
      return [
        ...this.rules.password_confirmation,
        () =>
          this.form.new_password === this.form.password_confirmation ||
          "Password confirmation does not match"
      ];
    }
  },
  methods: {
    async submit() {
      if (this.$refs.form.validate()) {
        try {
          this.loading = true;
          const user = auth.currentUser;
          const userCredential = credential(
            this.email,
            this.form.current_password
          );
          await reauthenticateWithCredential(user, userCredential);
          await updatePassword(user, this.form.new_password);
          this.$snackbar.showMessage({
            content: "Password changed successfully",
            variant: "success"
          });
          this.step = "menu";
        } catch (error) {
          this.$snackbar.showMessage({
            content: error.message,
            variant: "error"
          });
        }
        this.loading = false;
        this.$refs.form.reset();
        this.$refs.form.resetValidation();
      }
    }
  }
};
</script>
