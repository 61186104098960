import Vue from "vue";
import Vuex from "vuex";

import accountSettings from "./account.settings.module";
import drawer from "./drawer.module";
import snackbar from "./snackbar.module";
import subtest from "./subtest.module";
import user from "./user.module";

import { setSidebarItems } from "./plugins/drawer.plugin";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    accountSettings,
    drawer,
    snackbar,
    user,
    subtest
  },
  plugins: [setSidebarItems]
});
