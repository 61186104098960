import querystring from "querystring";
import api from "./api";
import authHeader from "@/helpers/auth-header";

const moduleName = "subtest";

export default {
  index(params) {
    return api().get(`${moduleName}?${querystring.stringify(params)}`, {
      headers: authHeader()
    });
  },
  create(data) {
    return api().post(`${moduleName}/create`, data, {
      headers: authHeader()
    });
  },
  find(id) {
    return api().get(`${moduleName}/${id}/find`, {
      headers: authHeader()
    });
  },
  update(id, data) {
    return api().put(`${moduleName}/${id}/update`, data, {
      headers: authHeader()
    });
  },
  delete(id) {
    return api().delete(`${moduleName}/${id}/delete`, {
      headers: authHeader()
    });
  },
  search(query) {
    return api().get(`${moduleName}/search?query=${query}`, {
      headers: authHeader()
    });
  },
  async findRunningSubtest(id) {
    const runningSubtest = await api().get(`${moduleName}/${id}/running`, {
      headers: authHeader()
    });

    return runningSubtest.data.data;
  },
  async findRunningSubtestQuestions(id) {
    const runningSubtestQuestions = await api().get(
      `${moduleName}/${id}/running/questions`,
      {
        headers: authHeader()
      }
    );

    return runningSubtestQuestions.data.data;
  },
  async findQuestion(id, params) {
    const question = await api().get(
      `${moduleName}/${id}/question?${querystring.stringify(params)}`,
      {
        headers: authHeader()
      }
    );

    return question.data.data;
  },
  answerQuestion(id, data) {
    return api().post(`${moduleName}/${id}/answer-question`, data, {
      headers: authHeader()
    });
  },
  endTest(id) {
    return api().post(
      `${moduleName}/${id}/end-test`,
      {},
      {
        headers: authHeader()
      }
    );
  },
  async findRunningSubtestById(runningSubtestId) {
    const runningSubtest = await api().get(
      `${moduleName}/${runningSubtestId}/running-subtest`,
      {
        headers: authHeader()
      }
    );

    return runningSubtest.data.data;
  },
  async findTestInformation(runningSubtestId) {
    const testInformation = await api().get(
      `${moduleName}/${runningSubtestId}/test-information`,
      {
        headers: authHeader()
      }
    );

    return testInformation.data.data;
  },
  async findCorrectAnswer(runningSubtestId, questionId) {
    const correctAnswer = await api().get(
      `${moduleName}/${runningSubtestId}/${questionId}/correct-answer`,
      {
        headers: authHeader()
      }
    );

    return correctAnswer.data.data;
  },
  resetRunningSubest(runningSubtestId) {
    return api().post(
      `${moduleName}/${runningSubtestId}/reset-subtest`,
      {},
      {
        headers: authHeader()
      }
    );
  },
  addDuration(runningSubtestId, data) {
    return api().post(`${moduleName}/${runningSubtestId}/add-duration`, data, {
      headers: authHeader()
    });
  }
};
