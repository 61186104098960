export function setSidebarItems(store) {
  store.subscribe((mutation, state) => {
    if (mutation.type === "user/setRole") {
      const role = state.user.role;
      const sidebarItems = state.drawer.sidebar_item_list.filter(item =>
        item.roles.includes(role)
      );
      store.dispatch("drawer/setSidebarItems", sidebarItems);
    }
  });
}
