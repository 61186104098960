<template>
  <v-container>
    <v-window v-model="step">
      <menu-window />
      <reset-password-window />
      <edit-profile />
      <edit-student-profile />
    </v-window>
  </v-container>
</template>

<script>
import MenuWindow from "@/components/MenuWindow.vue";
import ResetPasswordWindow from "@/components/ResetPasswordWindow.vue";
import EditProfile from "@/components/EditProfileWindow.vue";
import EditStudentProfile from "@/components/EditStudentProfileWindow.vue";

export default {
  name: "Settings.Account",
  components: {
    MenuWindow,
    ResetPasswordWindow,
    EditProfile,
    EditStudentProfile
  },
  computed: {
    step: {
      get() {
        return this.$store.getters["accountSettings/step"];
      },
      set(step) {
        this.$store.commit("accountSettings/setStep", step);
      }
    }
  }
};
</script>
