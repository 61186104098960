const StudentForm = () => import("../views/student/Form");
const StudentImport = () => import("@/views/student/Import");
const StudentIndex = () => import("../views/student/Index");

export default [
  {
    path: "/student",
    name: "Student.Index",
    component: StudentIndex,
    meta: {
      requiresAuth: true,
      roles: ["admin"]
    }
  },
  {
    path: "/student/create",
    name: "Student.Create",
    component: StudentForm,
    meta: {
      requiresAuth: true,
      roles: ["admin"]
    }
  },
  {
    path: "/student/:id/edit",
    name: "Student.Edit",
    component: StudentForm,
    meta: {
      requiresAuth: true,
      roles: ["admin"]
    }
  },
  {
    path: "/student/import",
    name: "Student.Import",
    component: StudentImport,
    meta: {
      requiresAuth: true,
      roles: ["admin"]
    }
  }
];
