import Vue from "vue";
import Router from "vue-router";
import store from "@/store";
import { EventBus } from "@/eventBus";
import { auth } from "@/firebase";
import { routesWithoutDrawer } from "./config";
import institute from "./institute";
import group from "./group";
import student from "./student";
import questionPack from "./question-pack";
import question from "./question";
import test from "./test";
import user from "./user";
import subtest from "./subtest";
import subtestType from "./subtest_type";
import subject from "./subject";
import result from "./result";
import school from "./school";
import major from "./major";
import Login from "@/views/Login";
import Register from "@/views/Register";
import ForgotPassword from "@/views/ForgotPassword";
import ResetPassword from "@/views/ResetPassword";
import Dashboard from "@/components/Dashboard";
import Settings from "@/views/settings/Index";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    // Delete this if the web has a landing page
    {
      path: "/",
      name: "Root",
      redirect: "/dashboard"
    },
    {
      path: "/login",
      name: "Login",
      component: Login,
      meta: {
        invalidIfAuthenticated: true
      }
    },
    {
      path: "/register",
      name: "Register",
      component: Register,
      meta: {
        invalidIfAuthenticated: true
      }
    },
    {
      path: "/forgot-password",
      name: "ForgotPassword",
      component: ForgotPassword,
      meta: {
        invalidIfAuthenticated: true
      }
    },
    {
      path: "/reset-password",
      name: "ResetPassword",
      component: ResetPassword,
      meta: {
        invalidIfAuthenticated: true
      }
    },
    {
      path: "/dashboard",
      name: "Dashboard",
      component: Dashboard,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/settings",
      name: "Settings",
      component: Settings,
      meta: {
        requiresAuth: true
      }
    },
    ...institute,
    ...group,
    ...student,
    ...questionPack,
    ...question,
    ...test,
    ...user,
    ...subtest,
    ...subtestType,
    ...subject,
    ...result,
    ...school,
    ...major
  ]
});

router.beforeEach((to, from, next) => {
  // Check if a route need to be authenticatiod
  const requiresAuth = to.matched.some(x => x.meta.requiresAuth);
  if (requiresAuth && !auth.currentUser) {
    next("/login");
  } else {
    next();
  }

  // Check if a route id valid if the user has been authenticated
  const invalidIfAuthenticated = to.matched.some(
    x => x.meta.invalidIfAuthenticated
  );
  if (invalidIfAuthenticated && auth.currentUser) {
    next("/dashboard");
  } else {
    next();
  }

  // Check if the user's role is valid for the intended route
  const role = store.getters["user/role"];
  // This variable's name is temporaryRole because the value is set to localStorage which is easily
  // to be changed by the user. The actual role is set in setRole mutation.
  const temporaryRole = localStorage.getItem("role");
  const hasAuthorization = to.matched.some(x => x.meta.roles !== undefined);
  if (hasAuthorization) {
    const isAuthorized = to.matched.some(
      x => x.meta.roles.includes(role) || x.meta.roles.includes(temporaryRole)
    );

    if (!isAuthorized) {
      EventBus.$emit("createSnackbar", {
        snackbar_message: "You are not authorized to access this page",
        variant: "error"
      });
      next(router.push({ path: from.path }));
    } else {
      next();
    }
  }

  // Set the drawer (because some routes don't need drawer)
  if (!routesWithoutDrawer.includes(router.currentRoute.name)) {
    store.dispatch("drawer/setDrawerStatus", true);
  } else {
    store.dispatch("drawer/setDrawerStatus", false);
  }
});

export default router;
