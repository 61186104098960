import firebase from "firebase/compat/app";
import {
  EmailAuthProvider,
  confirmPasswordReset,
  getAuth,
  onAuthStateChanged,
  reauthenticateWithCredential,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  updatePassword,
  verifyPasswordResetCode
} from "firebase/auth";
import firebaseConfigFile from "../firebase.json";

const firebaseConfig = firebaseConfigFile;
const firebaseApp = firebase.initializeApp(firebaseConfig);

const auth = getAuth(firebaseApp);
const credential = EmailAuthProvider.credential;

export {
  auth,
  credential,
  confirmPasswordReset,
  getAuth,
  onAuthStateChanged,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  reauthenticateWithCredential,
  updatePassword,
  verifyPasswordResetCode
};
