const TestIndex = () => import("../views/test/Index");
const TestForm = () => import("../views/test/Form");

export default [
  {
    path: "/test",
    name: "Test.Index",
    component: TestIndex,
    meta: {
      requiresAuth: true,
      roles: ["admin"]
    }
  },
  {
    path: "/test/create",
    name: "Test.Create",
    component: TestForm,
    meta: {
      requiresAuth: true,
      roles: ["admin"]
    }
  },
  {
    path: "/test/:id/edit",
    name: "Test.Edit",
    component: TestForm,
    meta: {
      requiresAuth: true,
      roles: ["admin"]
    }
  }
];
