import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

import en from "@/locale/en";

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: { en },
    current: "en"
  },
  theme: {
    themes: {
      light: {
        background: "#f4f4f7"
      }
    }
  }
});
