<template>
  <component v-bind:is="component"></component>
</template>
<script>
import { mapActions } from "vuex";
import StudentDashboard from "@/views/dashboard/Student";

export default {
  name: "Dashboard",
  components: {
    "student-dashboard": StudentDashboard
  },
  created() {
    this.setNavbarTitle("Dashboard");
  },
  computed: {
    component() {
      const role = this.role || localStorage.getItem("role");

      return {
        student: "student-dashboard"
      }[role];
    },
    role() {
      return this.$store.getters["user/role"];
    }
  },
  methods: {
    ...mapActions("drawer", ["setNavbarTitle"])
  }
};
</script>
