import { auth } from "@/firebase";
import router from "@/router";

const initialState = {
  role: null,
  is_logged_in: false,
  id_token: null,
  display_name: null,
  email: null
};

export default {
  namespaced: true,
  state: {
    ...initialState
  },
  actions: {
    async setUser({ commit }, user) {
      const {
        token,
        claims: { role }
      } = await user.getIdTokenResult();
      commit("setStatus");
      commit("setRole", role);
      commit("setIdToken", token);
      commit("setDisplayName", user.displayName);
      commit("setEmail", user.email);
    },
    async signOut({ commit }) {
      await auth.signOut();

      commit("resetState");
      localStorage.clear();
      router.push({ name: "Login" });
    }
  },
  mutations: {
    resetState(state) {
      state.role = null;
      state.is_logged_in = false;
    },
    setStatus(state) {
      state.is_logged_in = true;
    },
    setRole(state, role) {
      state.role = role;
      localStorage.setItem("role", role);
    },
    setIdToken(state, idToken) {
      state.id_token = idToken;
      localStorage.setItem("token", idToken);
    },
    setDisplayName(state, displayName) {
      state.display_name = displayName;
      localStorage.setItem("displayName", displayName);
    },
    setEmail(state, email) {
      state.email = email;
      localStorage.setItem("email", email);
    }
  },
  getters: {
    isLoggedIn: ({ is_logged_in }) => is_logged_in,
    role: ({ role }) => role,
    idToken: ({ id_token }) => id_token,
    displayName: ({ display_name }) => display_name,
    email: ({ email }) => email
  }
};
