<template>
  <div class="page">
    <div class="form">
      <v-card class="mx-auto">
        <v-card-text class="text-center">
          <v-avatar class="mb-4" size="64">
            <img src="@/assets/konstanta.png" alt="alt" />
          </v-avatar>
          <h1 class="font-weight-bold mb-3">Sign Up</h1>
          <h3 class="text--disabled font-weight-regular mb-10">
            Create new account
          </h3>
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
            @submit.prevent="submit"
          >
            <v-text-field
              v-model="form.name"
              :disabled="loading"
              :rules="rules.name"
              label="Name"
              prepend-icon="mdi-card-account-details-outline"
              validate-on-blur
            />
            <v-menu
              v-model="dateOfBirthMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="form.date_of_birth"
                  v-bind="attrs"
                  v-on="on"
                  :rules="rules.date_of_birth"
                  :disabled="loading"
                  label="Date of Birth"
                  prepend-icon="mdi-calendar"
                  readonly
                  validate-on-blur
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="form.date_of_birth"
                @input="dateOfBirthMenu = false"
                :max="maximumDate"
                :disabled="loading"
                no-title
                scrollable
              ></v-date-picker>
            </v-menu>
            <v-autocomplete
              v-model="form.school_id"
              :disabled="loading"
              :items="schools"
              :loading="schoolLoading"
              :rules="rules.school_id"
              :search-input.sync="schoolSearch"
              label="School"
              item-text="name"
              item-value="id"
              prepend-icon="mdi-office-building"
              cache-items
              flat
              hide-no-data
              validate-on-blur
            />
            <v-select
              v-model="form.class"
              :disabled="loading"
              :items="classItems"
              :rules="rules.class"
              label="Class"
              prepend-icon="mdi-google-classroom"
              validate-on-blur
            />
            <v-select
              v-model="form.group"
              :disabled="loading"
              :items="groupItems"
              :rules="rules.group"
              label="Group"
              prepend-icon="mdi-account-group"
              validate-on-blur
            />
            <v-text-field
              v-model="form.phone_number"
              :disabled="loading"
              :rules="rules.phone_number"
              label="Phone Number"
              prefix="+62"
              prepend-icon="mdi-phone"
              validate-on-blur
            />
            <v-text-field
              v-model="form.email"
              :disabled="loading"
              :rules="rules.email"
              label="E-mail"
              prepend-icon="mdi-email"
              validate-on-blur
            />
            <v-text-field
              v-model="form.password"
              @click:append="passwordShow = !passwordShow"
              :append-icon="passwordShow ? 'mdi-eye' : 'mdi-eye-off'"
              :disabled="loading"
              :rules="rules.password"
              :type="passwordShow ? 'text' : 'password'"
              label="Password"
              prepend-icon="mdi-lock"
              validate-on-blur
            />
            <v-text-field
              v-model="form.password_confirmation"
              @click:append="
                passwordConfirmationShow = !passwordConfirmationShow
              "
              :append-icon="
                passwordConfirmationShow ? 'mdi-eye' : 'mdi-eye-off'
              "
              :disabled="loading"
              :rules="rules.password_confirmation"
              :type="passwordConfirmationShow ? 'text' : 'password'"
              label="Password Confirmation"
              prepend-icon="mdi-lock"
              validate-on-blur
            />
            <v-autocomplete
              v-model="form.major_option1_id"
              :disabled="majorLoading"
              :items="majors"
              :loading="majorLoading"
              :rules="rules.major_option1_id"
              :search-input.sync="majorOption1Search"
              label="Major Option 1"
              item-text="name"
              item-value="id"
              prepend-icon="mdi-school"
              cache-items
              flat
              hide-no-data
              validate-on-blur
            />
            <v-autocomplete
              v-model="form.major_option2_id"
              :disabled="majorLoading"
              :items="majors"
              :loading="majorLoading"
              :rules="rules.major_option2_id"
              :search-input.sync="majorOption2Search"
              label="Major Option 2"
              item-text="name"
              item-value="id"
              prepend-icon="mdi-school"
              cache-items
              flat
              hide-no-data
              validate-on-blur
            />
            <v-text-field
              v-model="form.parents_name"
              :disabled="loading"
              :rules="rules.parents_name"
              label="Parents' Name"
              prepend-icon="mdi-account-child"
              validate-on-blur
            />
            <v-text-field
              v-model="form.parents_phone_number"
              :disabled="loading"
              :rules="rules.parents_phone_number"
              label="Parents' Phone Number"
              prefix="+62"
              prepend-icon="mdi-phone"
              validate-on-blur
            />
            <v-text-field
              v-model="form.parents_email"
              :disabled="loading"
              :rules="rules.parents_email"
              label="Parents' E-mail"
              prepend-icon="mdi-email"
              validate-on-blur
            />
            <v-btn
              class="mb-2"
              color="primary"
              type="submit"
              :loading="loading"
              block
              >Sign up</v-btn
            >
            <v-btn :to="{ name: 'Login' }" block link text
              >Sign In Instead</v-btn
            >
          </v-form>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<style scoped>
.page {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px 1rem;
  height: 100%;
  min-height: 100vh;
}
.form {
  width: 100%;
  max-width: 400px;
}
</style>

<script>
import AuthService from "@/services/auth";
import MajorService from "@/services/major";
import SchoolService from "@/services/school";

export default {
  name: "Register",
  data() {
    return {
      passwordShow: false,
      passwordConfirmationShow: false,
      dateOfBirthMenu: false,
      form: {
        name: "",
        email: "",
        password: "",
        password_confirmation: "",
        phone_number: "",
        date_of_birth: "",
        school_id: "",
        class: "",
        group: "",
        major_option1_id: "",
        major_option2_id: "",
        parents_name: "",
        parents_phone_number: "",
        parents_email: ""
      },
      rules: {
        name: [
          v => !!v || "Name is required",
          v =>
            (v.length >= 5 && v.length <= 50) ||
            "Name must be greater than 5 and less then 50",
          v =>
            new RegExp(/^[\w\-\s]+$/i).test(v) ||
            "Name must only contain alphanumeric characters"
        ],
        email: [
          v => !!v || "E-mail is required",
          v =>
            /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
              v
            ) || "E-mail must be valid"
        ],
        password: [
          v => !!this.dataId || !!v || "Password is required",
          v =>
            !!this.dataId ||
            (v.length >= 6 && v.length <= 50) ||
            "Password must be greater than 6 and less then 30"
        ],
        password_confirmation: [
          v => !!this.dataId || !!v || "Confirmation assword is required",
          v =>
            !!this.dataId ||
            v === this.form.password ||
            "Password confirmation does not match"
        ],
        phone_number: [
          v => !!v || "Phone number is required",
          v =>
            /^8\d{8,11}$/.test(v) ||
            "Phone number does not match with the format"
        ],
        date_of_birth: [v => !!v || "Date of birth is required"],
        school: [v => !!v || "School is required"],
        class: [v => !!v || "Class is required"],
        group: [v => !!v || "Group is required"],
        major_option1_id: [v => !!v || "Major option 1 is required"],
        major_option2_id: [v => !!v || "Major option 2 is required"],
        parents_name: [
          v => !!v || "Parents' name is required",
          v =>
            (v.length >= 5 && v.length <= 50) ||
            "Parents' name must be greater than 5 and less then 50",
          v =>
            new RegExp(/^[\w\-\s]+$/i).test(v) ||
            "Parents' name must only contain alphanumeric characters"
        ],
        parents_phone_number: [
          v => !!v || "Parents' phone number is required",
          v =>
            /^8\d{8,11}$/.test(v) ||
            "Parents' phone number does not match with the format"
        ],
        parents_email: [
          v => !!v || "Parents' e-mail is required",
          v =>
            /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
              v
            ) || "Parents' e-mail must be valid"
        ]
      },
      classItems: [
        "7",
        "8",
        "9",
        "10.1",
        "10.2",
        "10.3",
        "10.4",
        "10.5",
        "10.6",
        "10.7",
        "10.8",
        "10.9",
        "10.10",
        "11.1",
        "11.2",
        "11.3",
        "11.4",
        "11.5",
        "11.6",
        "11.7",
        "11.8",
        "11.9",
        "11.10",
        "11 BAHASA 1",
        "11 BAHASA 2",
        "11 BAHASA 3",
        "11 IPA 1",
        "11 IPA 2",
        "11 IPA 3",
        "11 IPA 4",
        "11 IPA 5",
        "11 IPA 6",
        "11 IPA 7",
        "11 IPA 8",
        "11 IPA 9",
        "11 IPA 10",
        "11 IPS 1",
        "11 IPS 2",
        "11 IPS 3",
        "11 IPS 4",
        "11 IPS 5",
        "11 IPS 6",
        "11 IPS 7",
        "11 IPS 8",
        "11 IPS 9",
        "11 IPS 10",
        "12 BAHASA 1",
        "12 BAHASA 2",
        "12 BAHASA 3",
        "12 IPA 1",
        "12 IPA 2",
        "12 IPA 3",
        "12 IPA 4",
        "12 IPA 5",
        "12 IPA 6",
        "12 IPA 7",
        "12 IPA 8",
        "12 IPS 1",
        "12 IPS 2",
        "12 IPS 3",
        "12 IPS 4",
        "12 IPS 5",
        "12 IPS 6",
        "12 IPS 7",
        "12 IPS 8",
        "12 IPS 9",
        "12 IPS 10"
      ],
      groupItems: ["SAINTEK", "SOSHUM", "CAMPURAN", "SMP"],
      loading: false,
      valid: false,
      schools: [],
      schoolLoading: false,
      schoolSearch: null,
      majors: [],
      majorLoading: false,
      majorOption1Search: null,
      majorOption2Search: null
    };
  },
  computed: {
    maximumDate() {
      const tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
      return new Date(Date.now() - tzoffset).toISOString().slice(0, -1);
    }
  },
  watch: {
    schoolSearch(val) {
      val && val !== this.form.school_id && this.searchSchools(val);
    },
    majorOption1Search(val) {
      val && val !== this.form.major_option1_id && this.searchMajors(val);
    },
    majorOption2Search(val) {
      val && val !== this.form.major_option2_id && this.searchMajors(val);
    }
  },
  methods: {
    async searchSchools(query) {
      this.schoolLoading = true;
      const schools = await SchoolService.search(query);
      this.schools = schools.data.data.schools;
      this.schoolLoading = false;
    },
    async searchMajors(query) {
      this.majorlLoading = true;
      const majors = await MajorService.search(query);
      this.majors = majors.data.data.majors;
      this.majorLoading = false;
    },
    async submit() {
      this.loading = true;
      try {
        await AuthService.signUp({
          ...this.form,
          phone_number: `+62${this.form.phone_number}`,
          parents_phone_number: `+62${this.form.parents_phone_number}`
        });
        this.$snackbar.showMessage({
          content: "Sign up success",
          variant: "success"
        });
        this.$router.push({ name: "Login" });
      } catch (error) {
        this.loading = false;
      }
    }
  }
};
</script>
