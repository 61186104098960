<template>
  <v-app-bar v-if="showDrawer" color="green" app dark elevate-on-scroll>
    <v-app-bar-nav-icon
      v-if="role !== 'student'"
      @click="setDrawer(!drawer)"
    ></v-app-bar-nav-icon>

    <router-link :to="{ name: 'Dashboard' }" style="color: white"
      ><v-toolbar-title>Konstanta Education</v-toolbar-title></router-link
    >

    <v-spacer></v-spacer>

    <v-menu :close-on-content-click="true" offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" icon>
          <v-avatar color="brown" size="36">
            <span class="white--text">{{ initials }}</span>
          </v-avatar>
        </v-btn>
      </template>
      <v-card min-width="250">
        <div class="d-flex flex-column align-center mb-4 pt-8">
          <v-btn icon>
            <v-avatar color="brown" size="72">
              <span class="white--text headline">{{ initials }}</span>
            </v-avatar>
          </v-btn>
          <span class="font-weight-medium mt-6">{{ displayName }}</span>
          <span class="subtitle font-weight-regular">{{ email }}</span>
        </div>
        <v-divider />
        <v-list-item :to="{ name: 'Settings' }" link>
          <v-list-item-content>
            <v-list-item-title>Settings</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider />
        <div class="d-flex flex-column align-center pa-2">
          <v-btn @click="signOut" color="error">
            <v-icon left>mdi-exit-to-app</v-icon>
            Sign out
          </v-btn>
        </div>
      </v-card>
    </v-menu>
  </v-app-bar>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Navbar",
  computed: {
    ...mapGetters("drawer", ["drawer", "showDrawer", "navbarTitle"]),
    ...mapGetters("user", ["role", "displayName", "email"]),
    initials() {
      const rgx = new RegExp(/(\p{L}{1})\p{L}+/, "gu");

      const initials =
        [...localStorage.getItem("displayName").matchAll(rgx)] || [];

      return (
        (initials.shift()?.[1] || "") + (initials.pop()?.[1] || "")
      ).toUpperCase();
    }
  },
  methods: {
    ...mapActions("drawer", ["setDrawer"]),
    ...mapActions("user", ["signOut"])
  }
};
</script>
