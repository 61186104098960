const QuestionIndex = () => import("../views/question/Index");
const QuestionForm = () => import("../views/question/Form");

export default [
  {
    path: "/question",
    name: "Question.Index",
    component: QuestionIndex,
    meta: {
      requiresAuth: true,
      roles: ["admin"]
    }
  },
  {
    path: "/question/create",
    name: "Question.Create",
    component: QuestionForm,
    meta: {
      requiresAuth: true,
      roles: ["admin"]
    }
  },
  {
    path: "/question/:id/edit",
    name: "Question.Edit",
    component: QuestionForm,
    meta: {
      requiresAuth: true,
      roles: ["admin"]
    }
  }
];
