<template>
  <v-window-item :value="windowName">
    <v-toolbar flat>
      <v-btn @click="step = 'menu'" icon>
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <v-toolbar-title>
        {{ currentTitle }}
      </v-toolbar-title>
    </v-toolbar>
    <div class="pa-4">
      <v-form
        v-model="valid"
        ref="form"
        @submit.prevent="submit"
        lazy-validation
      >
        <v-text-field
          v-model="form.name"
          :counter="30"
          :disabled="loading"
          :rules="rules.name"
          label="Name"
          required
        />
        <v-text-field
          v-model="form.email"
          :disabled="loading"
          :rules="rules.email"
          label="E-mail"
          type="email"
          required
        />
        <v-text-field
          v-model="form.phone_number"
          :disabled="loading"
          :rules="rules.phone_number"
          label="Phone Number"
          prefix="+62"
          required
        />
        <v-btn
          :disabled="!valid || loading"
          :loading="loading"
          color="success"
          class="mt-2"
          type="submit"
        >
          Submit
        </v-btn>
      </v-form>
    </div>
  </v-window-item>
</template>

<script>
import { mapGetters } from "vuex";
import UserService from "@/services/user";

export default {
  name: "Settings.EditProfileWindow",
  data: () => ({
    windowName: "edit-profile",
    valid: false,
    loading: false,
    form: {
      name: "",
      email: "",
      phone_number: ""
    },
    rules: {
      name: [
        v => !!v || "Name is required",
        v => {
          (v.length >= 5 && v.length <= 50) ||
            "Name must be greater than 5 and less then 30";
        },
        v =>
          new RegExp(/^[\w\-\s]+$/i).test(v) ||
          "Name must only contain alphanumeric characters"
      ],
      email: [
        v => !!v || "E-mail is required",
        v =>
          /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) || "E-mail must be valid"
      ],
      phone_number: [
        v => !!v || "Password is required",
        v =>
          /^8\d{8,11}$/.test(v) || "Phone number does not match with the format"
      ]
    }
  }),
  async created() {
    const user = await UserService.me();
    const { name, email, phoneNumber, role } = user.data.data;
    this.form = { name, email, phone_number: phoneNumber.slice(3), role };
  },
  computed: {
    ...mapGetters("user", ["email"]),
    ...mapGetters("accountSettings", ["menus"]),
    step: {
      get() {
        return this.$store.getters["accountSettings/step"];
      },
      set(step) {
        this.$store.commit("accountSettings/setStep", step);
      }
    },
    currentTitle() {
      return this.step !== "menu"
        ? this.menus.find(menu => menu.id === this.step).title
        : "Menu";
    }
  },
  methods: {
    async submit() {
      if (this.$refs.form.validate()) {
        try {
          this.loading = true;
          await UserService.editProfile({
            ...this.form,
            phone_number: `+62${this.form.phone_number}`
          });
          this.$snackbar.showMessage({
            content: "Password changed successfully",
            variant: "success"
          });
          window.location.reload();
        } catch (error) {
          this.$snackbar.showMessage({
            content: error.message,
            variant: "error"
          });
        } finally {
          this.loading = false;
        }
      }
    }
  }
};
</script>
