<template>
  <div class="page">
    <div class="form">
      <v-card class="mx-auto">
        <v-card-text class="text-center">
          <v-avatar class="mb-4" size="64">
            <img src="@/assets/konstanta.png" alt="alt" />
          </v-avatar>
          <h1 class="font-weight-bold mb-3">
            Sign In
          </h1>
          <h3 class="text--disabled font-weight-regular mb-10">
            Sign in to your account
          </h3>
          <v-form @submit.prevent="submit">
            <v-text-field
              v-model="form.email"
              :disabled="loading"
              label="E-mail"
              prepend-icon="mdi-email"
            />
            <v-text-field
              v-model="form.password"
              @click:append="show = !show"
              :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
              :disabled="loading"
              :type="show ? 'text' : 'password'"
              label="Password"
              prepend-icon="mdi-lock"
              hide-details
            />
            <div class="d-flex justify-end mt-2">
              <router-link :to="{ name: 'ForgotPassword' }"
                >Forgot Password?</router-link
              >
            </div>
            <v-btn
              class="mb-2 mt-4"
              color="primary"
              type="submit"
              :loading="loading"
              block
              >Sign in</v-btn
            >
            <v-btn :to="{ name: 'Register' }" block link text
              >Create New Account</v-btn
            >
          </v-form>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<style scoped>
.page {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px 1rem;
  height: 100%;
  min-height: 100vh;
}
.form {
  width: 100%;
  max-width: 400px;
}
</style>

<script>
import { mapActions } from "vuex";
import { auth, signInWithEmailAndPassword } from "../firebase";

export default {
  data() {
    return {
      form: {
        email: "",
        password: ""
      },
      error: null,
      step: 1,
      show: false,
      loading: false
    };
  },
  methods: {
    ...mapActions("user", ["setUser"]),
    async submit() {
      this.loading = true;
      try {
        const { email, password } = this.form;
        const { user } = await signInWithEmailAndPassword(
          auth,
          email,
          password
        );
        this.setUser(user);
        this.$router.push({ name: "Dashboard" });
      } catch (error) {
        this.loading = false;
        this.$snackbar.showMessage({
          content: error.message,
          variant: "error"
        });
      }
    }
  }
};
</script>
