import querystring from "querystring";
import api from "./api";
import authHeader from "@/helpers/auth-header";

const moduleName = "user";

export default {
  index(params) {
    return api().get(`${moduleName}?${querystring.stringify(params)}`, {
      headers: authHeader()
    });
  },
  create(data) {
    return api().post(`${moduleName}/create`, data, {
      headers: authHeader()
    });
  },
  find(id) {
    return api().get(`${moduleName}/${id}/find`, {
      headers: authHeader()
    });
  },
  update(id, data) {
    return api().put(`${moduleName}/${id}/update`, data, {
      headers: authHeader()
    });
  },
  delete(id) {
    return api().delete(`${moduleName}/${id}/delete`, {
      headers: authHeader()
    });
  },
  search({ query, type }) {
    return api().get(
      `${moduleName}/search?${querystring.stringify({ query, type })}`,
      {
        headers: authHeader()
      }
    );
  },
  deleteMultiple(data) {
    return api().post(`${moduleName}/delete-multiple`, data, {
      headers: authHeader()
    });
  },
  editProfile(data) {
    return api().put(`${moduleName}/edit-profile`, data, {
      headers: authHeader()
    });
  },
  me() {
    return api().get(`${moduleName}/me`, {
      headers: authHeader()
    });
  }
};
