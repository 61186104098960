const UserIndex = () => import("../views/user/Index");
const UserForm = () => import("../views/user/Form");
const UserDeleteMultiple = () => import("../views/user/DeleteMultiple");

export default [
  {
    path: "/user",
    name: "User.Index",
    component: UserIndex,
    meta: {
      requiresAuth: true,
      roles: ["admin"]
    }
  },
  {
    path: "/user/create",
    name: "User.Create",
    component: UserForm,
    meta: {
      requiresAuth: true,
      roles: ["admin"]
    }
  },
  {
    path: "/user/:id/edit",
    name: "User.Edit",
    component: UserForm,
    meta: {
      requiresAuth: true,
      roles: ["admin"]
    }
  },
  {
    path: "/user/delete-multiple",
    name: "User.DeleteMultiple",
    component: UserDeleteMultiple,
    meta: {
      requiresAuth: true,
      roles: ["admin"]
    }
  }
];
