export default {
  badge: "Badge",
  close: "Close",
  dataIterator: {
    noResultsText: "No matching records found",
    loadingText: "Loading items..."
  },
  dataTable: {
    itemsPerPageText: "Rows per page:",
    ariaLabel: {
      sortDescending: "Sorted descending.",
      sortAscending: "Sorted ascending.",
      sortNone: "Not sorted.",
      activateNone: "Activate to remove sorting.",
      activateDescending: "Activate to sort descending.",
      activateAscending: "Activate to sort ascending."
    },
    sortBy: "Sort by"
  },
  dataFooter: {
    itemsPerPageText: "Items per page:",
    itemsPerPageAll: "All",
    nextPage: "Next page",
    prevPage: "Previous page",
    firstPage: "First page",
    lastPage: "Last page",
    pageText: "{0}-{1} of {2}"
  },
  datePicker: {
    itemsSelected: "{0} selected",
    nextMonthAriaLabel: "Next month",
    nextYearAriaLabel: "Next year",
    prevMonthAriaLabel: "Previous month",
    prevYearAriaLabel: "Previous year"
  },
  noDataText: "No data available",
  carousel: {
    prev: "Previous visual",
    next: "Next visual",
    ariaLabel: {
      delimiter: "Carousel slide {0} of {1}"
    }
  },
  calendar: {
    moreEvents: "{0} more"
  },
  fileInput: {
    counter: "{0} files",
    counterSize: "{0} files ({1} in total)"
  },
  timePicker: {
    am: "AM",
    pm: "PM"
  },
  pagination: {
    ariaLabel: {
      wrapper: "Pagination Navigation",
      next: "Next page",
      previous: "Previous page",
      page: "Goto Page {0}",
      currentPage: "Current Page, Page {0}"
    }
  },
  rating: {
    ariaLabel: {
      icon: "Rating {0} of {1}"
    }
  },
  modal: {
    resetTest: {
      title: "Reset selected test?",
      description:
        "This action is irreversible. Resetting this test means other data that containing this test will also be deleted.",
      textButton: "Reset"
    },
    forceEndTest: {
      title: "Force end selected test?",
      description:
        "This action is irreversible. Forcing this test to end means the user won't be able to continue their test.",
      textButton: "Force End"
    },
    forceEndSubtest: {
      title: "Force end selected subtest?",
      description:
        "This action is irreversible. Forcing this subtest to end means the user won't be able to continue their subtest.",
      textButton: "Force End"
    },
    resetSubtest: {
      title: "Reset selected subtest?",
      description:
        "This action is irreversible. Resetting this subtest means other data that containing this subtest will also be deleted.",
      textButton: "Reset"
    },
    deleteData: {
      title: "Delete selected data?",
      description:
        "This action is irreversible. Deleting this data means other data that containing this data will also be deleted.",
      textButton: "Delete"
    },
    startTest: {
      title: "Confirm Start Test",
      description: `
      <p>
        By clicking <b>START TEST</b> the test and the timer will be started
      </p>`,
      textButton: "Confirm"
    },
    startSubtest: {
      title: "Confirm Start Subtest",
      description: `
      <p>
        By clicking <b>START SUBTEST</b> the subtest and the timer will be started
      </p>
      <p>
        Please note that the answer will be recorded only if
        <b>Next Question Button</b>, <b>Previous Question Button</b>, or
        <b>Question Number Button</b> clicked
      </p>`,
      textButton: "Confirm"
    },
    endTest: {
      title: "Confirm End Test",
      description: `
      <p>
        By clicking <b>CONFIRM</b> the test and the subtests will be ended
        and calculated
      </p>`,
      textButton: "Confirm"
    },
    endSubtest: {
      title: "End this subtest?",
      description: `
      <p>
        By clicking <b>CONFIRM</b> the test and the subtests will be ended
        and calculated
      </p>`,
      confirmCommand: "Please click confirm to end the test",
      timeLeft: "There is {0} left to continue",
      unansweredQuestion: " and 1 unanswered question",
      unansweredQuestionPlural: " and {0} unanswered questions",
      textButton: "Confirm"
    }
  },
  downloadResult: "Download Result",
  generateResult: "Generate Result",
  forceEndSubtest: "Force End Subtest",
  option: "Option",
  resetTest: "Reset Test",
  forceEndTest: "Force End Test",
  studentName: "Student Name",
  score: "Score",
  action: "Action",
  resetSubtest: "Reset Subtest",
  checkStudentTestResult: "Check {0}'s test result",
  startSubtest: "Start Subtest",
  result: "Result",
  studentResult: "Student Result",
  testList: "Test List",
  groupTest: "Group Test",
  search: "Search",
  filterGroup: "Filter this group",
  filterInstitute: "Filter this instute",
  filterSchool: "Filter this school",
  filterQuestionPack: "Filter this question pack",
  copyQuestion: "Copy question",
  copyQuestions: "Copy questions",
  edit: "Edit",
  delete: "Delete",
  newQuestion: "New Question",
  question: "Question",
  testResult: "Test Result",
  groupList: "Group List",
  testGroupList: "{0} Group List",
  importFromCsv: "Import From CSV",
  newStudent: "New Student",
  student: "Student",
  updateGroup: "Update group",
  recalculateScore: "Recalculate Score",
  renewCertificate: "Renew certificate",
  renewCertificateInformation:
    "Renew certificate if the result is not formatted correctly.",
  renewCertificateInformation2:
    "This will also recalculate the score if there's any difference with the existing result.",
  ongoingTests: "Ongoing Tests",
  completedTests: "Completed Tests",
  save: "Save",
  minutes: "minutes",
  minutesLeft: "{0} minutes left",
  startTest: "Start test",
  scored: "scored",
  endTest: "End Test",
  tests: "Tests",
  addDuration: "Add {0} minutes",
  copyTest: "Copy test",
  testEnded: "Test has ended",
  timeLeft: "{0} left",
  subtestEnded: "Subtest has ended",
  previousQuestion: "Previous Question",
  nextQuestion: "Next Question",
  endSubtest: "End This Subtest",
  calculateIrt: "Calculate IRT",
  reviewSubtest: "Review Subtest",
  downloadIrtResult: "Download IRT Result",
  recalculateIrt: "Recalculate IRT"
};
