const MajorIndex = () => import("../views/major/Index");
const MajorForm = () => import("../views/major/Form");

export default [
  {
    path: "/major",
    name: "Major.Index",
    component: MajorIndex,
    meta: {
      requiresAuth: true,
      roles: ["admin"]
    }
  },
  {
    path: "/major/create",
    name: "Major.Create",
    component: MajorForm,
    meta: {
      requiresAuth: true,
      roles: ["admin"]
    }
  },
  {
    path: "/major/:id/edit",
    name: "Major.Edit",
    component: MajorForm,
    meta: {
      requiresAuth: true,
      roles: ["admin"]
    }
  }
];
