import querystring from "querystring";
import api from "./api";
import authHeader from "@/helpers/auth-header";

const moduleName = "test";

export default {
  index(params) {
    return api().get(`${moduleName}?${querystring.stringify(params)}`, {
      headers: authHeader()
    });
  },
  create(data) {
    return api().post(`${moduleName}/create`, data, {
      headers: authHeader()
    });
  },
  find(id) {
    return api().get(`${moduleName}/${id}/find`, {
      headers: authHeader()
    });
  },
  update(id, data) {
    return api().put(`${moduleName}/${id}/update`, data, {
      headers: authHeader()
    });
  },
  delete(id) {
    return api().delete(`${moduleName}/${id}/delete`, {
      headers: authHeader()
    });
  },
  startTest(data) {
    return api().post(`${moduleName}/start-test`, data, {
      headers: authHeader()
    });
  },
  search(query) {
    return api().get(`${moduleName}/search?query=${query}`, {
      headers: authHeader()
    });
  },
  subtests(id) {
    return api().get(`${moduleName}/${id}/subtests`, {
      headers: authHeader()
    });
  },
  async runningSubtests(runningTestId) {
    const subtests = await api().get(
      `${moduleName}/${runningTestId}/running-subtests`,
      {
        headers: authHeader()
      }
    );

    return subtests.data.data;
  },
  startSubtest(data) {
    return api().post(`${moduleName}/start-subtest`, data, {
      headers: authHeader()
    });
  },
  endTest(id) {
    return api().post(
      `${moduleName}/${id}/end-test`,
      {},
      {
        headers: authHeader()
      }
    );
  },
  resetRunningTest(runningTestId) {
    return api().post(
      `${moduleName}/${runningTestId}/reset-test`,
      {},
      {
        headers: authHeader()
      }
    );
  },
  studentTestResult(studentId) {
    return api().get(`${moduleName}/result/student/${studentId}`, {
      headers: authHeader()
    });
  },
  groupTestResult() {
    return api().get(`${moduleName}/result/group`, {
      headers: authHeader()
    });
  },
  registeredGroups(testId, params) {
    return api().get(
      `${moduleName}/${testId}/groups?${querystring.stringify(params)}`,
      {
        headers: authHeader()
      }
    );
  },
  addDuration(runningTestId, data) {
    return api().post(`${moduleName}/${runningTestId}/add-duration`, data, {
      headers: authHeader()
    });
  },
  copy(id) {
    return api().post(
      `${moduleName}/${id}/copy`,
      {},
      {
        headers: authHeader()
      }
    );
  },
  calculateIrt(id) {
    return api().post(
      `${moduleName}/${id}/calculate-irt`,
      {},
      {
        headers: authHeader()
      }
    );
  },
  recalculateIrt(id) {
    return api().post(
      `${moduleName}/${id}/recalculate-irt`,
      {},
      {
        headers: authHeader()
      }
    );
  }
};
