const GroupIndex = () => import("../views/group/Index");
const GroupForm = () => import("../views/group/Form");

export default [
  {
    path: "/group",
    name: "Group.Index",
    component: GroupIndex,
    meta: {
      requiresAuth: true,
      roles: ["admin"]
    }
  },
  {
    path: "/group/create",
    name: "Group.Create",
    component: GroupForm,
    meta: {
      requiresAuth: true,
      roles: ["admin"]
    }
  },
  {
    path: "/group/:id/edit",
    name: "Group.Edit",
    component: GroupForm,
    meta: {
      requiresAuth: true,
      roles: ["admin"]
    }
  }
];
