const InstituteIndex = () => import("../views/institute/Index");
const InstituteForm = () => import("../views/institute/Form");

export default [
  {
    path: "/institute",
    name: "Institute.Index",
    component: InstituteIndex,
    meta: {
      requiresAuth: true,
      roles: ["admin"]
    }
  },
  {
    path: "/institute/create",
    name: "Institute.Create",
    component: InstituteForm,
    meta: {
      requiresAuth: true,
      roles: ["admin"]
    }
  },
  {
    path: "/institute/:id/edit",
    name: "Institute.Edit",
    component: InstituteForm,
    meta: {
      requiresAuth: true,
      roles: ["admin"]
    }
  }
];
