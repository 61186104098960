const SubtestTypeIndex = () => import("../views/subtest_type/Index");
const SubtestTypeForm = () => import("../views/subtest_type/Form");

export default [
  {
    path: "/subtest_type",
    name: "SubtestType.Index",
    component: SubtestTypeIndex,
    meta: {
      requiresAuth: true,
      roles: ["admin"]
    }
  },
  {
    path: "/subtest_type/create",
    name: "SubtestType.Create",
    component: SubtestTypeForm,
    meta: {
      requiresAuth: true,
      roles: ["admin"]
    }
  },
  {
    path: "/subtest_type/:id/edit",
    name: "SubtestType.Edit",
    component: SubtestTypeForm,
    meta: {
      requiresAuth: true,
      roles: ["admin"]
    }
  }
];
