const QuestionPackIndex = () => import("../views/question-pack/Index");
const QuestionPackForm = () => import("../views/question-pack/Form");

export default [
  {
    path: "/question-pack",
    name: "QuestionPack.Index",
    component: QuestionPackIndex,
    meta: {
      requiresAuth: true,
      roles: ["admin"]
    }
  },
  {
    path: "/question-pack/create",
    name: "QuestionPack.Create",
    component: QuestionPackForm,
    meta: {
      requiresAuth: true,
      roles: ["admin"]
    }
  },
  {
    path: "/question-pack/:id/edit",
    name: "QuestionPack.Edit",
    component: QuestionPackForm,
    meta: {
      requiresAuth: true,
      roles: ["admin"]
    }
  }
];
