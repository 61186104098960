const initialState = {
  step: "menu",
  menus: [
    {
      id: "edit-profile",
      title: "Edit profile"
    },
    {
      id: "reset-password",
      title: "Reset password"
    },
    {
      id: "edit-student-profile",
      title: "Edit student profile"
    }
  ]
};

export default {
  namespaced: true,
  state: {
    ...initialState
  },
  actions: {
    setStep({ commit }, step) {
      commit("setStep", step);
    }
  },
  mutations: {
    setStep(state, step) {
      state.step = step;
    }
  },
  getters: {
    step: ({ step }) => step,
    menus: ({ menus }) => menus
  }
};
