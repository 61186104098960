<template>
  <div>
    <v-dialog v-model="show" max-width="500">
      <v-card>
        <v-card-title
          >{{ ongoingTest.name }} <br />
          {{ dynamicTimeDifference(ongoingTest.testEnd) }}</v-card-title
        >
        <v-divider />
        <div class="pa-4">
          <v-skeleton-loader v-if="loading" type="paragraph" :loading="true" />
          <div v-if="!loading">
            <div
              v-for="(subtestList, subtestType) in subtests"
              :key="subtestType"
            >
              <span class="font-weight-medium">{{ subtestType }}</span>
              <div class="mt-2">
                <div
                  v-for="subtest in subtestList"
                  class="ml-4 mb-1"
                  :key="subtest.id"
                >
                  <span
                    @click="startSubtestDialog(subtest)"
                    :class="
                      `${testAvailabilityColor(
                        subtest.status
                      )}--text pointer-cursor`
                    "
                    >{{ subtest.position }}. {{ subtest.name }}</span
                  >
                  <div class="text--disabled">
                    {{ subtest.status !== "ongoing" ? subtest.duration : "" }}
                    {{
                      subtest.status === "ongoing"
                        ? `${dynamicTimeDifference(
                            subtest.testEnd,
                            {
                              unit: "minute"
                            },
                            true
                          )}`
                        : $vuetify.lang.t("$vuetify.minutes")
                    }}
                    <span v-if="subtest.score !== -1"
                      >• {{ $vuetify.lang.t("$vuetify.scored") }}
                      <span class="success--text">{{
                        subtest.score
                      }}</span></span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="!loading" class="d-flex flex-column align-center pa-2">
          <v-btn
            @click="endTestDialog = true"
            :disabled="
              dynamicTimeDifference(ongoingTest.testEnd, {
                unit: 'minute'
              }) === $vuetify.lang.t('$vuetify.testEnded') &&
                ongoingTest.isIrt === 1
            "
            color="error"
          >
            {{ $vuetify.lang.t("$vuetify.endTest") }}
          </v-btn>
        </div>
      </v-card>
    </v-dialog>

    <modal
      v-model="startSubtestConfirmationDialog"
      :loading="startSubtestLoading"
      :handler="startSubtest"
      :html="true"
      :persistent="startSubtestLoading"
      :title="$vuetify.lang.t('$vuetify.modal.startSubtest.title')"
      :description="$vuetify.lang.t('$vuetify.modal.startSubtest.description')"
      :text-button="$vuetify.lang.t('$vuetify.modal.startSubtest.textButton')"
      :max-width="'500'"
    />

    <modal
      v-model="endTestDialog"
      :loading="endTestLoading"
      :handler="endTest"
      :html="true"
      :persistent="endTestLoading"
      :title="$vuetify.lang.t('$vuetify.modal.endTest.title')"
      :description="$vuetify.lang.t('$vuetify.modal.endTest.description')"
      :text-button="$vuetify.lang.t('$vuetify.modal.endTest.textButton')"
      :max-width="'500'"
    />
  </div>
</template>

<script>
import formatDistanceStrict from "date-fns/formatDistanceStrict";
import Modal from "@/components/Modal";
import TestService from "@/services/test";
import TimeService from "@/services/time";

export default {
  name: "OngoingTestModal",
  props: {
    value: Boolean,
    ongoingTest: {
      id: String,
      name: String,
      testEnd: Date
    },
    loading: Boolean,
    subtests: {}
  },
  data() {
    return {
      startSubtestConfirmationDialog: false,
      selectedSubtest: {},
      startSubtestLoading: false,
      endTestDialog: false,
      endTestLoading: false,
      time: new Date()
    };
  },
  created() {
    this.getTime();
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    }
  },
  components: {
    Modal
  },
  methods: {
    async startSubtest() {
      try {
        this.startSubtestLoading = true;
        const subtest = await TestService.startSubtest({
          running_test_id: this.ongoingTest.id,
          subtest_id: this.selectedSubtest.id
        });
        this.startSubtestLoading = false;
        this.startSubtestConfirmationDialog = false;
        this.ongoingTestDialog = false;

        this.$router.push({
          name: "Subtest.Subtest",
          params: { id: subtest.data.data.id }
        });
      } catch (error) {
        this.startSubtestLoading = false;
      }
    },
    async endTest() {
      try {
        this.endTestLoading = true;
        await TestService.endTest(this.ongoingTest.id);
        await new Promise(resolve => setTimeout(resolve, 1000));
        if (this.$route.name === "Dashboard") {
          window.location.reload();
        } else {
          this.$router.push({ name: "Dashboard" });
        }
      } catch (error) {
        this.endTestLoading = false;
      }
    },
    startSubtestDialog(subtest) {
      if (subtest.status === "ongoing") {
        this.$router.push({
          name: "Subtest.Subtest",
          params: { id: subtest.id }
        });
      }
      if (subtest.status === "done") {
        this.$router.push({
          name: "Subtest.Review",
          params: { id: subtest.id }
        });
      } else if (subtest.status === "started") {
        if (
          this.dynamicTimeDifference(this.ongoingTest.testEnd, {
            unit: "minute"
          }) === this.$vuetify.lang.t("$vuetify.testEnded")
        )
          return;
        this.startSubtestConfirmationDialog = true;
        this.selectedSubtest = subtest;
      }
    },
    testAvailabilityColor(status) {
      switch (status) {
        case "not yet started":
          return "default";
        case "started":
          return "primary";
        case "ended":
          return "error";
        case "ongoing":
          return "warning";
        case "done":
          return "success";
      }
    },
    async getTime() {
      const date = await TimeService.time();
      this.time = new Date(date);
    },
    dynamicTimeDifference(dateTime, option = {}, isSubtest = false) {
      dateTime = dateTime === undefined ? new Date() : new Date(dateTime);
      if (this.time > dateTime) {
        if (isSubtest) return this.$vuetify.lang.t("$vuetify.subtestEnded");
        return this.$vuetify.lang.t("$vuetify.testEnded");
      }
      return `${this.$vuetify.lang.t(
        "$vuetify.timeLeft",
        formatDistanceStrict(dateTime, this.time, option)
      )}`;
    },
    coy(dateTime, option = {}, isSubtest = false) {
      console.log(this.dynamicTimeDifference(dateTime, option, isSubtest));
    }
  }
};
</script>

<style scoped>
.pointer-cursor:hover {
  cursor: pointer;
}
</style>
