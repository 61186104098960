<template>
  <div class="page">
    <div class="form">
      <v-card class="mx-auto">
        <v-card-text class="text-center">
          <v-avatar class="mb-4" size="64">
            <img src="@/assets/konstanta.png" alt="alt" />
          </v-avatar>
          <h1 class="font-weight-bold mb-3">
            Forgot Password?
          </h1>
          <h3 class="text--disabled font-weight-regular mb-10">
            Enter your e-mail address to request a password reset
          </h3>
          <v-form @submit.prevent="submit">
            <v-text-field
              v-model="form.email"
              :disabled="loading"
              label="E-mail"
              prepend-icon="mdi-email"
            />
            <v-btn
              class="mb-2 mt-4"
              color="primary"
              type="submit"
              :loading="loading"
              block
              >Reset Password</v-btn
            >
          </v-form>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<style scoped>
.page {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px 1rem;
  height: 100%;
  min-height: 100vh;
}
.form {
  width: 100%;
  max-width: 400px;
}
</style>

<script>
import { auth, sendPasswordResetEmail } from "../firebase";

export default {
  data() {
    return {
      form: {
        email: ""
      },
      error: null,
      loading: false
    };
  },
  methods: {
    async submit() {
      this.loading = true;
      try {
        const { email } = this.form;
        await sendPasswordResetEmail(auth, email);
        this.$snackbar.showMessage({
          content: "Reset link has been sent. Please check your e-mail",
          variant: "success"
        });
      } catch (error) {
        this.$snackbar.showMessage({
          content: error.message,
          variant: "error"
        });
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
