<template>
  <v-window-item :value="windowName">
    <v-toolbar flat>
      <v-btn @click="step = 'menu'" icon>
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <v-toolbar-title>
        {{ currentTitle }}
      </v-toolbar-title>
    </v-toolbar>
    <div class="pa-4">
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
        @submit.prevent="submit"
      >
        <v-row>
          <v-col>
            <v-menu
              v-model="dateOfBirthMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="form.date_of_birth"
                  v-bind="attrs"
                  v-on="on"
                  :rules="rules.date_of_birth"
                  :disabled="loading"
                  label="Date of Birth"
                  readonly
                  validate-on-blur
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="form.date_of_birth"
                @input="dateOfBirthMenu = false"
                :max="maximumDate"
                :disabled="loading"
                no-title
                scrollable
              ></v-date-picker>
            </v-menu>
            <v-autocomplete
              v-model="form.school_id"
              :disabled="loading"
              :items="schools"
              :loading="schoolLoading"
              :rules="rules.school"
              :search-input.sync="searchSchool"
              label="School"
              item-text="name"
              item-value="id"
              cache-items
              flat
              hide-no-data
              validate-on-blur
            />
            <v-select
              v-model="form.class"
              :disabled="loading"
              :items="classItems"
              :rules="rules.class"
              label="Class"
              validate-on-blur
            />
            <v-select
              v-model="form.group"
              :disabled="loading"
              :items="groupItems"
              :rules="rules.group"
              label="Group"
              validate-on-blur
            />
            <v-autocomplete
              v-model="form.major_option1_id"
              :disabled="majorOption1Loading"
              :items="majors"
              :loading="majorOption1Loading"
              :rules="rules.major_option1_id"
              :search-input.sync="searchMajorOption1"
              label="Major Option 1"
              item-text="name"
              item-value="id"
              cache-items
              flat
              hide-no-data
              validate-on-blur
            />
            <v-autocomplete
              v-model="form.major_option2_id"
              :disabled="majorOption2Loading"
              :items="majors"
              :loading="majorOption2Loading"
              :rules="rules.major_option2_id"
              :search-input.sync="searchMajorOption2"
              label="Major Option 2"
              item-text="name"
              item-value="id"
              cache-items
              flat
              hide-no-data
              validate-on-blur
            />
            <v-text-field
              v-model="form.parents_name"
              :disabled="loading"
              :rules="rules.parents_name"
              label="Parents' Name"
              validate-on-blur
            />
            <v-text-field
              v-model="form.parents_phone_number"
              :disabled="loading"
              :rules="rules.parents_phone_number"
              label="Parents' Phone Number"
              prefix="+62"
              validate-on-blur
            />
            <v-text-field
              v-model="form.parents_email"
              :disabled="loading"
              :rules="rules.parents_email"
              label="Parents' E-mail"
              validate-on-blur
            />
            <v-btn
              :disabled="!valid"
              :loading="loading"
              color="success"
              class="mt-2"
              type="submit"
            >
              Submit
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </div>
  </v-window-item>
</template>

<script>
import { mapGetters } from "vuex";
import StudentService from "@/services/student";
import SchoolService from "@/services/school";
import MajorService from "@/services/major";

export default {
  name: "Settings.EditStudentProfileWindow",
  data: () => ({
    windowName: "edit-student-profile",
    valid: false,
    loading: false,
    form: {
      date_of_birth: "",
      school_id: "",
      class: "",
      group: "",
      major_option1_id: "",
      major_option2_id: "",
      parents_name: "",
      parents_phone_number: "",
      parents_email: ""
    },
    dateOfBirthMenu: false,
    rules: {
      date_of_birth: [v => !!v || "Date of birth is required"],
      school_id: [v => !!v || "School is required"],
      class: [v => !!v || "Class is required"],
      group: [v => !!v || "Group is required"],
      major_option1_id: [v => !!v || "Major option 1 is required"],
      major_option2_id: [v => !!v || "Major option 2 is required"],
      parents_name: [
        v => !!v || "Parents' name is required",
        v =>
          (v.length >= 5 && v.length <= 50) ||
          "Parents' name must be greater than 5 and less then 50",
        v =>
          new RegExp(/^[\w\-\s]+$/i).test(v) ||
          "Parents' name must only contain alphanumeric characters"
      ],
      parents_phone_number: [
        v => !!v || "Parents' phone number is required",
        v =>
          /^8\d{8,11}$/.test(v) ||
          "Parents' phone number does not match with the format"
      ],
      parents_email: [
        v => !!v || "Parents' e-mail is required",
        v =>
          /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) || "Parents' e-mail must be valid"
      ]
    },
    classItems: [
      "7",
      "8",
      "9",
      "10.1",
      "10.2",
      "10.3",
      "10.4",
      "10.5",
      "10.6",
      "10.7",
      "10.8",
      "10.9",
      "10.10",
      "11.1",
      "11.2",
      "11.3",
      "11.4",
      "11.5",
      "11.6",
      "11.7",
      "11.8",
      "11.9",
      "11.10",
      "11 BAHASA 1",
      "11 BAHASA 2",
      "11 BAHASA 3",
      "11 IPA 1",
      "11 IPA 2",
      "11 IPA 3",
      "11 IPA 4",
      "11 IPA 5",
      "11 IPA 6",
      "11 IPA 7",
      "11 IPA 8",
      "11 IPA 9",
      "11 IPA 10",
      "11 IPS 1",
      "11 IPS 2",
      "11 IPS 3",
      "11 IPS 4",
      "11 IPS 5",
      "11 IPS 6",
      "11 IPS 7",
      "11 IPS 8",
      "11 IPS 9",
      "11 IPS 10",
      "12 BAHASA 1",
      "12 BAHASA 2",
      "12 BAHASA 3",
      "12 IPA 1",
      "12 IPA 2",
      "12 IPA 3",
      "12 IPA 4",
      "12 IPA 5",
      "12 IPA 6",
      "12 IPA 7",
      "12 IPA 8",
      "12 IPS 1",
      "12 IPS 2",
      "12 IPS 3",
      "12 IPS 4",
      "12 IPS 5",
      "12 IPS 6",
      "12 IPS 7",
      "12 IPS 8",
      "12 IPS 9",
      "12 IPS 10"
    ],
    groupItems: ["SAINTEK", "SOSHUM", "CAMPURAN", "SMP"],
    searchSchool: null,
    searchMajorOption1: null,
    searchMajorOption2: null,
    schoolLoading: false,
    majorOption1Loading: false,
    majorOption2Loading: false,
    schools: [],
    majors: []
  }),
  watch: {
    searchSchool(val) {
      val && val !== this.form.school_id && this.searchSchools(val);
    },
    searchMajorOption1(val) {
      val && val !== this.form.major_option1_id && this.searchMajors(val);
    },
    searchMajorOption2(val) {
      val && val !== this.form.major_option2_id && this.searchMajors(val);
    }
  },
  async created() {
    const student = await StudentService.me();
    const {
      dateOfBirth: date_of_birth,
      schoolId: school_id,
      majorOption1Id: major_option1_id,
      majorOption2Id: major_option2_id,
      parentsName: parents_name,
      parentsPhoneNumber: parents_phone_number,
      parentsEmail: parents_email,
      examGroup: group,
      ...studentData
    } = student.data.data;
    this.form = {
      ...studentData,
      group,
      date_of_birth: date_of_birth !== null ? date_of_birth.slice(0, 10) : null,
      school_id,
      major_option1_id,
      major_option2_id,
      parents_name,
      parents_phone_number:
        parents_phone_number !== null ? parents_phone_number.slice(3) : null,
      parents_email
    };
    const school = await SchoolService.find(school_id);
    this.schools.push({ id: school_id, name: school.data.data.name });
    const majorOption1 = await MajorService.find(major_option1_id);
    this.majors.push({
      id: major_option1_id,
      name: majorOption1.data.data.name
    });
    const majorOption2 = await MajorService.find(major_option2_id);
    this.majors.push({
      id: major_option2_id,
      name: majorOption2.data.data.name
    });
  },
  computed: {
    ...mapGetters("user", ["email"]),
    ...mapGetters("accountSettings", ["menus"]),
    step: {
      get() {
        return this.$store.getters["accountSettings/step"];
      },
      set(step) {
        this.$store.commit("accountSettings/setStep", step);
      }
    },
    currentTitle() {
      return this.step !== "menu"
        ? this.menus.find(menu => menu.id === this.step).title
        : "Menu";
    },
    maximumDate() {
      const tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
      return new Date(Date.now() - tzoffset).toISOString().slice(0, -1);
    }
  },
  methods: {
    async searchSchools(query) {
      this.schoolLoading = true;
      const schools = await SchoolService.search(query);
      this.schools = schools.data.data.schools;
      this.schoolLoading = false;
    },
    async searchMajors(query) {
      this.majorlLoading = true;
      const majors = await MajorService.search(query);
      this.majors = majors.data.data.majors;
      this.majorOption1Loading = false;
      this.majorOption2Loading = false;
    },
    async submit() {
      if (this.$refs.form.validate()) {
        try {
          this.loading = true;
          await StudentService.updateProfile({
            ...this.form,
            parents_phone_number: `+62${this.form.parents_phone_number}`
          });
          window.location.reload();
        } catch (error) {
          this.$snackbar.showMessage({
            content: error.message,
            variant: "error"
          });
        } finally {
          this.loading = false;
        }
      }
    }
  }
};
</script>
