const ResultGroupIndex = () => import("../views/result/group/Index");
const ResultGroupTest = () => import("../views/result/group/Test");
const ResultGroupTestResult = () => import("../views/result/group/Result");
const ResultStudentIndex = () => import("../views/result/student/Index");
const ResultStudentTest = () => import("@/views/result/student/Result");
const ResultTestIndex = () => import("@/views/result/test/Index");
const ResultTestGroupList = () => import("@/views/result/test/GroupList");

export default [
  {
    path: "/result/group",
    name: "Result.Group.Index",
    component: ResultGroupIndex,
    meta: {
      requiresAuth: true,
      roles: ["admin"]
    }
  },
  {
    path: "/result/group/:group_id/test",
    name: "Result.Group.Test",
    component: ResultGroupTest,
    meta: {
      requiresAuth: true,
      roles: ["admin"]
    }
  },
  {
    path: "/result/group/:group_id/test/:test_id",
    name: "Result.Group.TestResult",
    component: ResultGroupTestResult,
    meta: {
      requiresAuth: true,
      roles: ["admin"]
    }
  },
  {
    path: "/result/student",
    name: "Result.Student.Index",
    component: ResultStudentIndex,
    meta: {
      requiresAuth: true,
      roles: ["admin"]
    }
  },
  {
    path: "/result/student/:student_id",
    name: "Result.Student.TestResult",
    component: ResultStudentTest,
    meta: {
      requiresAuth: true,
      roles: ["admin"]
    }
  },
  {
    path: "/result/test",
    name: "Result.Test.Index",
    component: ResultTestIndex,
    meta: {
      requiresAuth: true,
      roles: ["admin"]
    }
  },
  {
    path: "/result/test/:test_id/group",
    name: "Result.Test.GroupList",
    component: ResultTestGroupList,
    meta: {
      requiresAuth: true,
      roles: ["admin"]
    }
  }
];
