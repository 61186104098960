<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="2">
        <v-sheet rounded="lg" outlined>
          <v-list color="transparent" flat>
            <v-list-item-group v-model="selectedLink" color="primary">
              <v-list-item
                v-for="link in links"
                :key="link.id"
                :value="link.id"
                link
              >
                <v-list-item-icon>
                  <v-icon v-text="link.icon" />
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ link.title }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-sheet>
      </v-col>

      <v-col>
        <v-sheet rounded="lg" outlined>
          <component v-bind:is="selectedLink"></component>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Account from "@/views/settings/Account";
export default {
  name: "Settings",
  data: () => ({
    selectedLink: "account",
    links: [
      {
        id: "account",
        icon: "mdi-account",
        title: "Account"
      }
    ]
  }),
  components: {
    account: Account
  }
};
</script>
