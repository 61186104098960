import querystring from "querystring";
import api from "./api";
import authHeader from "@/helpers/auth-header";

const moduleName = "student";

export default {
  index(params) {
    return api().get(`${moduleName}?${querystring.stringify(params)}`, {
      headers: authHeader()
    });
  },
  create(data) {
    return api().post(`${moduleName}/create`, data, {
      headers: authHeader()
    });
  },
  find(id, profile = false) {
    return api().get(
      `${moduleName}/${id}/find${profile ? "?profile=true" : ""}`,
      {
        headers: authHeader()
      }
    );
  },
  update(id, data) {
    return api().put(`${moduleName}/${id}/update`, data, {
      headers: authHeader()
    });
  },
  delete(id) {
    return api().delete(`${moduleName}/${id}/delete`, {
      headers: authHeader()
    });
  },
  addGroup(data) {
    return api().post(`${moduleName}/add-group`, data, {
      headers: authHeader()
    });
  },
  registeredGroup() {
    return api().get(`${moduleName}/group`, {
      headers: authHeader()
    });
  },
  tests() {
    return api().get(`${moduleName}/tests`, {
      headers: authHeader()
    });
  },
  ongoingTests() {
    return api().get(`${moduleName}/ongoing-tests`, {
      headers: authHeader()
    });
  },
  completedTests() {
    return api().get(`${moduleName}/completed-tests`, {
      headers: authHeader()
    });
  },
  me() {
    return api().get(`${moduleName}/me`, {
      headers: authHeader()
    });
  },
  updateProfile(data) {
    return api().put(`${moduleName}/update-profile`, data, {
      headers: authHeader()
    });
  }
};
