const SubjectIndex = () => import("../views/subject/Index");
const SubjectForm = () => import("../views/subject/Form");

export default [
  {
    path: "/subject",
    name: "Subject.Index",
    component: SubjectIndex,
    meta: {
      requiresAuth: true,
      roles: ["admin"]
    }
  },
  {
    path: "/subject/create",
    name: "Subject.Create",
    component: SubjectForm,
    meta: {
      requiresAuth: true,
      roles: ["admin"]
    }
  },
  {
    path: "/subject/:id/edit",
    name: "Subject.Edit",
    component: SubjectForm,
    meta: {
      requiresAuth: true,
      roles: ["admin"]
    }
  }
];
