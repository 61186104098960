<template>
  <v-window-item :value="windowName">
    <v-list color="transparent">
      <v-list-item
        v-for="menu in menus"
        :key="menu.id"
        @click="step = menu.id"
        link
      >
        <v-list-item-content>
          <v-list-item-title>{{ menu.title }}</v-list-item-title>
        </v-list-item-content>
        <v-list-item-action>
          <v-btn icon>
            <v-icon color="grey lighten-1">mdi-menu-right</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
    </v-list>
  </v-window-item>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "MenuWindow",
  data: () => ({
    windowName: "menu"
  }),
  computed: {
    ...mapGetters("accountSettings", ["menus"]),
    step: {
      get() {
        return this.$store.getters["accountSettings/step"];
      },
      set(step) {
        this.$store.commit("accountSettings/setStep", step);
      }
    }
  }
};
</script>
